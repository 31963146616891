const messages = {
    en: {

    },

    es: {
        atras: 'Atrás',
        cerrar: 'Cerrar',
        aceptar: 'Aceptar',
        common: {
            cerrar: 'Cerrar',
            fechaVacia: 'Fecha Vacía',
            fechaRellena: 'Fecha Rellena',
            mesActual: 'Mes Actual',
            yes: 'Sí',
            no: 'No',
            desde: 'Desde',
            hasta: 'Hasta',
            buscar: 'Buscar',
            anadir: 'Añadir',
            cargar: 'Cargar',
            ver: 'Ver',
            editar: 'Editar',
            duplicar: 'Duplicar',
            guardar: 'Guardar',
            aceptar: 'Aceptar',
            guardarComo: 'Guardar Como',
            eliminar: 'Eliminar',
            cancelar: 'Cancelar',
            forzar: 'Forzar cambio',
            confirmar: 'Confirmar',
            compartir: 'Compartir',
            filtro: 'Filtro',
            nombre: 'Nombre',
            bloqueado: 'Bloqueado',
            desbloqueado: 'Desbloqueado',
            move: 'Mover',
            copy: 'Copiar',
            paste: 'Pegar',
            any: 'Cualquiera',
            actualizarTabla: 'Actualizar datos de tabla',
            exportarExcel: 'Exportar a Excel',
            consultasFavoritas: 'Consultas Favoritas',
            firstSearch: 'Realice una búsqueda para obtener resultados',
            noResults: 'La búsqueda no ha dado resultados',
            configurarColumnas: 'Configurar columnas',
            consultaGuardada: 'Consulta Guardada correctamente',
            consultaBorrada: 'Consulta Borrada correctamente',
            consultaCompartida: 'Consulta Compartida correctamente',
            empleados: 'Empleados',
            filtrar: 'Filtrar',
            cleanFilter: 'Limpiar Filtro',
            configurarFiltros: 'Configurar Filtros',
            guardarConsulta: 'Guardar Consulta',
            sharedConsulta: 'Compartir Consulta',
            sobreescribir: 'Sobreescribir',
            cambiosmasivos: 'Cambios Masivos',
            actualizarMasivamente: 'Actualizar masivamente',
            desmarcarSeleccionados: 'Desmarcar Seleccionados',
            configurarAgregaciones: 'Configurar Agregaciones',
            ver: 'Ver',
            sin: 'Sin',
            borrarFiltro: "Borrar Filtro",
            today: "Hoy",
            addDoc: "Añadir Documento"
        },
        main_menu: {
            inicio: 'Inicio',
            clientes: 'Clientes',
            firmas: 'Firmas',
            pedidos: 'Citas',
            inspecciones: 'Mis Certificaciones',
            facturas: 'Facturas',
            marcas: 'Marcas',
            formatos: 'Formatos',
            modelos: 'Modelos',
            coches: 'Coches',
            conflictos: 'Incidencias',
            usuarios: 'Usuarios',
            configuracion: 'Configuración',
            cerrar_sesion: 'Cerrar sesión',
            ventas: 'Ventas',
            gruposfuncionales: 'Grupos Funcionales'
        },
        dashboard: {
            anyo: 'Año',
            mes: 'Mes',
            cliente: 'Cliente',
        },
        perfil: {
            perfil: 'Perfil',
            cambiar_pass: 'Cambiar Contraseña',
            new_pass: 'Nueva Contraseña',
            repeat_new_pass: 'Repita Nueva Contraseña',
            actual_pass: 'Contraseña Actual',
            cliente_contacto: 'Contacto',
            nombre: 'Nombre',
            firma: 'Añadir firma',
        },
        ventas: {
            ventas: 'Ventas',
            codigoCentro: 'Código Centro',
            nombreCentro: 'Nombre Centro',
            fechaVenta: 'Fecha Venta',
            fechaGrabacion: 'Fecha Grabación',
            vin: 'VIN',
            numeroStock: 'Número Stock',
            modelo: 'Modelo',
            version: 'Versión',
            anyoModelo: 'Año Modelo',
            fechaMatriculacion: 'Fecha Matriculación',
            matricula: 'Matrícula',
            tipoVehiculo: 'Tipo Vehículo',
            kilometraje: 'Kilometraje',
            ivaDeducible: 'IVA Deducible',
            garantia: 'Garantía',
            ventaUrgente: 'Venta Urgente',
            comienzoGarantiaReestreno: 'Comienzo Garantia Reestreno',
            duracion: 'Duración',
            fechaCompra: 'Fecha Compra',
            fechaCreacion: 'Fecha Creación',
            claveCompraVO: 'Clave Compra VO',
            precioCompraVO: 'Precio Compra VO',
            costeMntoOExposicion: 'Coste Mnto o Exposición',
            costeGarantia: 'Coste Garantía',
            costeReacondicionamiento: 'Coste Reacondicionamiento',
            precioVentaNeto: 'Precio Venta Neto',
            precioVentaNetoIva: 'Precio Venta Neto IVA',
            canalVenta: 'Canal Venta',
            vendedor: 'Vendedor',
            cliente: 'Cliente',
            visitasPagina: 'Visitas Página',
            consultasACentro: 'Consultas al Centro',
            enlacesEnviados: 'Enlaces Enviados',
            diasEnStock: 'Días en Stock',
            calculoContribucion: 'Cálculo Contribución',
            fechaImportacion: 'Fecha Importación',
            inspeccion: 'Certificación',
            calificacion: 'Calificación Certificación',
            desde: 'Desde',
            hasta: 'Hasta',
            anyo: 'Año',
            mes: 'Mes'
        },
        usuarios: {
            login: {
                usuario: 'Usuario',
                contrasena: 'Contraseña',
                entrar: 'Entrar',
                olvide_mi_contrasena: 'Olvidé mi contraseña'
            },
            usuarios: {
                usuarios: 'Usuarios'
            },
            usuario_form: {
                username: 'Username',
                nombre: 'Nombre',
                apellidos: 'Apellidos',
                telefono: 'Teléfono',
                email: 'Email',
                iniciales: 'Iniciales',
                roles: 'Roles',
                usuario: 'Usuario',
                editar_usuario: 'Editar usuario',
                nuevo_usuario: 'Nuevo usuario',
                usuario_guardado: 'Usuario guardado',
                rol: 'Rol',
                tipoTecnico: 'Tipo de técnico',
                clientes_asociados: 'Clientes asociados',
                cliente: 'Cliente',
                clienteDefecto: 'Cliente Defecto',
                add: 'Añadir',
                inspector_data: 'Datos de inspector',
                tecnico_data: 'Datos del técnico',
                foto: 'Seleccionar Foto',
                firma: 'Firma',
                select_firma: 'Seleccionar Firma',
                departamento: 'Departamento',
                generatedPassword: 'Contraseña generada',
                activo: 'Activo'
            }
        },
        facturas: {
            facturas: 'Facturas',
            numero: 'Número Factura',
            estado: 'Estado',
            cliente: 'Cliente',
            fecha_emision: 'Fecha Emisión',
            fecha_vencimiento: 'Fecha Vencimiento',
            subtotal: 'Subtotal',
            totalIva: 'Total IVA',
            totalFactura: 'Total Factura',
            lineas: 'Líneas',
            desde: 'Desde',
            hasta: 'Hasta',
            generar: 'Generar Factura',
            a_quien_facturar: 'A Quién realizar las facturas',
            facturar_hasta_dia: 'Facturar hasta el día:',
            que_facturar: 'Qué Facturar',
            comoFacturar: 'Facturar a nombre de:',
            envio_email_automatico: 'Enviar email con la factura automáticamente a los clientes',
            factura_guardada: 'Factura Guardada',
            concepto: 'Concepto',
            codigo_inspeccion: 'Certificación',
            codigo_pedido: 'Cita',
            matricula: 'Matrícula',
            bastidor: 'Bastidor',
            total: 'Total',
            tipoIva: 'Tipo IVA',
            precio_unitario: 'Precio Unitario',
            cantidad: 'Cantidad',
            factura: 'Factura',
            editar_factura: 'Editar Factura',
            motivo_cancel: 'Motivo Cancelación',
            facturas_generadas: 'Facturas Generadas',
        },
        pedidos: {
            pedidos: {
                nuevo: 'Nueva cita',
                pedidos: 'Citas',
                fecha_creacion: 'Fecha Creación',
                fecha_solicitada_desde: 'Fecha Solicitada Desde',
                fecha_solicitada_hasta: 'Fecha Solicitada Hasta'
            },
            pedido_form: {
                editar_pedido: 'Editar cita',
                nuevo_pedido: 'Nueva cita',
                pedido_guardado: 'Cita guardada con éxito',
                pedido: 'Cita',
                codigo: 'Código',
                importe: 'Importe',
                tipo_pedido: 'Tipo de cita',
                fecha_solicitada: 'Fecha Solicitada',
                fecha_solicitada_desde: 'Fecha Solicitada Desde',
                fecha_solicitada_hasta: 'Fecha Solicitada Hasta',
                fecha_visita: 'Fecha visita',
                hora: 'Hora',
                hora_inicio: 'Hora inicio',
                hora_fin: 'Hora fin',
                dia: 'Día',
                cliente: 'Cliente',
                cif: 'CIF',
                codigo_postal: 'Código postal',
                direccion: 'Dirección',
                poblacion: 'Población',
                provincia: 'Provincia',
                pais: 'País',
                matricula: 'Matrícula',
                bastidor: 'Bastidor',
                marca: 'Marca',
                modelo: 'Modelo',
                estado: 'Estado',
                tipo_inspeccion: 'Tipo de certificación',
                comercial: 'Comercial',
                cliente: 'Cliente',
                bps: 'BPS/Next KM',
                proxima_itv: 'Próxima ITV',
                pasar_itv: 'Tiene que pasar ITV',
                minutos_estimados: 'Minutos estimados',
                importe: 'Importe',
                inspector: 'Inspector',
                pedido_preparado: 'Cita preparada correctamente, se han creado sus Inspecciones',
                formato: 'Formato',
                motivo_cancel: 'Motivo cancelación',
                fecha_creacion_desde: 'Fecha Creación Desde',
                fecha_creacion_hasta: 'Fecha Creación Hasta',
                fecha_creacion: 'Fecha Creación',
                vehiculos: 'Vehículos',
                desde: 'Desde',
                hasta: 'Hasta',
                concesionario: 'Concesionario',
                color: 'Color',
                usuario: 'Usuario',
                departamento: 'Departamento',
                comision: 'Comisión',
                kilometros: 'Kilómetros',
                centro_porsche: 'Centro Porsche',
                observaciones: 'Observaciones',
                documentacionAportada: 'Documentación aportada'
            }
        },
        inspecciones: {
            inspecciones: {
                certificaciones: 'Mis Certificaciones'
            },
            inspeccion_form: {
                editar_inspeccion: 'Editar certificación',
                nueva_inspeccion: 'Nueva certificación',
                inspeccion_guardada: 'Certificación guardada con éxito',
                inspeccion: 'Certificación',
                tipo_inspeccion: 'Tipo Certificación',
                conflictos: 'Incidencias',
                observaciones: 'Observaciones',
                no_conflictos: 'Esta certificación no tiene Incidencias',
                justificantes: 'Justificantes',
                controlCalidad: 'Documentos (calidad)',
                documentos: 'Documentos',
                fotosConNombre: 'Fotos',
                formato: 'Formulario de inspección',
                ordenesdetaller: 'Órdenes de taller',
                fichero_unico: 'Descargar fichero único',
                codigo: 'Código',
                pedido: 'Cita',
                cliente: 'Cliente',
                calificacion: 'Calificación',
                estado: 'Estado',
                departamento: 'Departamento',
                situacionTaller: 'Situación taller',
                mandarATaller: 'Mandar a taller',
                confirmacionEnvioATaller: 'Se enviará el vehículo al primer departamento del taller.',
                cambiarEstado: 'Cambiar estado',
                fechaEstimada: 'Fecha estimada',
                matricula: 'Matrícula',
                bastidor: 'Bastidor',
                marca: 'Marca',
                modelo: 'Modelo',
                fecha_solicitada: 'Fecha Solicitada',
                fecha_solicitada_desde: 'Fecha Solicitada Desde',
                fecha_solicitada_hasta: 'Fecha Solicitada Hasta',
                motivo_observaciones: "Observaciones/Motivo",
                fecha_entrada: 'Fecha Entrada',
                fecha_visita: 'Fecha Visita',
                fecha_planificada: 'Fecha Planificada',
                hora: 'Hora',
                fecha_real: 'Fecha Realizada',
                direccion: 'Dirección',
                cp: 'C.P.',
                poblacion: 'Población',
                pais: 'País',
                tipo_pedido: 'Tipo Cita',
                comentario_inspector: 'Comentario Inspector',
                comentario_resolucion: 'Comentario Resolución',
                resolucion: 'Resolución',
                caracteristicas: 'Características',
                no_caracteristicas: 'No hay Características',
                comprobaciones: 'Comprobaciones',
                no_comprobaciones: 'No hay Comprobaciones',
                inspector: 'Inspector',
                con_conflictos: 'Con Incidencias',
                fecha_creacion: 'Fecha Creación Cita',
                desde: 'Desde',
                hasta: 'Hasta',
                duracion_estimada: 'Duración estimada',
                duracion: 'Duración',
                importe: 'Importe',
                fecha_visita_new: 'Nueva Fecha Visita',
                inspector_new: 'Nuevo Inspector',
                hora_new: 'Nueva Hora',
                importe_new: 'Nuevo Importe',
                motivo: 'Motivo cambio fecha',
                motivo_situacion: 'Motivo cambio situación',
                motivo_rechazo: 'Motivo rechazo',
                estimar_tarea: 'Estimar tarea',
                paso_a_estado: 'Paso a estado...',
                terminar_tarea: 'Terminar tarea',
                confirmacionFinTarea: 'Se dará por terminada la tarea y se pasará al siguiente departamento.',
                confirmacion_aceptacion_justificantes: '¿Seguro que quiere aceptar los justificantes y marcar la cerfificación como buena?',
                tratamiento_ionizado: 'Tratamiento Ionizado',
                generar_pdf: 'Generar fichero PDF',
                generar_zip: 'Generar fichero ZIP',
                descargar: 'Descargar',
                anadir_documento: 'Añadir Documento',
                estadoVehiculo: 'Estado vehículo',
                estado: 'Estado',
                usuario: 'Usuario',
                fecha: 'Fecha',
                historicoEstados: 'Histórico de estados',
                cerrar: 'Cerrar',
                cambioEstado: 'Cambio de estado vehículo',
                cambiarEstado: 'Cambiar estado del vehículo',
                finalizar: 'Finalizar',
                visible: 'Foto visible',
                oculto: 'Foto oculta',
                nombre: 'Nombre foto',
                documentacionAportada: 'Documentación aportada por el cliente',
                solicitarGarantia: 'Solicitar garantía',
                enviarATaller: 'Enviar a taller',
                nuevaOrdenTaller: 'Nueva orden de taller',
                guardarDocumentacionAportada: 'Guardar documentación aportada',
                fechaIniGarantia: 'Inicio garantía',
                fechaFinGarantia: 'Fin garantía',
                ubicacion: 'Ubicación vehículo',
                ubicacionVehiculo: 'Ubicación Vehículo',
                fechaCitaProgramada:'F. Cita Program.',
                fechaRevisado:'F. Revisado',
                fechaEnviadoTallerMeca:'F. Env. Taller (Meca)',
                fechaTerminadoTallerMeca:'F. Termin. Taller (Meca)',
                fechaEnviadoTallerCarr:'F. Env. Taller (Carr)',
                fechaTerminadoTallerCarr:'F. Termin. Taller (Carr)',
                fechaEnviadoTallerLimp:'F. Env. Taller (Limp)',
                fechaTerminadoTallerLimp:'F. Termin. Taller (Limp)',
                fechaVenta:'F. Venta',
                fechaEntradaDepartamento:'F. Entrada en departamento',
                fechaPrevistaTarea:'F. Prevista de fin de tareas',
                fechaFinPrevisto:'F. fin previsto',
                estadoVehiculo: 'Estado Vehículo',
                valorar_arreglos: 'Valorar arreglos',
                pendiente_partes: 'Pendiente de partes',
                estadoPendientePartes_comentario: 'Paso a pendiente de partes (inspector)',
                valoracionArreglos: {
                    importeChapa: 'Valoración de carrocería',
                    importeMecanica: 'Valoración de mecánica',
                    fotosConNombre: 'Documentación gráfica'
                },
                controlCalidad: {
                    fotosConNombre: 'Documentación gráfica'
                }
            },
            ordentaller: {
                defecto: 'Defecto',
                trabajo: 'Trabajo a realizar',
                tipoTrabajo: 'Tipo',
                destinatario: 'e-Mail del destinatario',
                add: 'Añadir e-Mail',
                trabajos: 'Trabajos a realizar',
                cabecera: 'Cabecera de la orden de taller',
                override: 'Actualizar',
                create: 'Crear nueva'
            }
        },
        conflictos: {
            conflictos: {
                conflictos: 'Incidencias'
            },
            conflicto_form: {
                editar_conflicto: 'Editar incidencia',
                nuevo_conflicto: 'Nueva incidencia',
                conflicto_guardado: 'Incidencia guardada con éxito',
                conflicto: 'Incidencia',
                codigoInspeccion: 'Código Certificación',
                tipo_conflicto: 'Tipo Incidencia',
                fecha_conflicto: 'Fecha Incidencia',
                fecha_resolucion: 'Fecha Resolución',
                cliente: 'Cliente',
                inspeccion: 'Certificación',
                resolucion: 'Resolución',
                comentarios: 'Comentarios inspector',
                comentarios_resolucion: 'Comentarios resolucion',
                comentarios_rechazo: 'Comentarios rechazo',
                matricula: 'Matrícula',
                bastidor: 'Bastidor',
                modelo: 'Modelo',
                marca: 'Marca',
                coche: 'Coche',
                estandar: 'Estándar',
                subestandar: 'Sub-Estándar',
                documentos_adjuntos: 'Documentos Adjuntos',
                desde: 'Desde',
                hasta: 'Hasta'
            }
        },
        coches: {
            coches: {
                coches: 'Coches'
            },
            coche_form: {
                matricula: 'Matrícula',
                bastidor: 'Bastidor',
                modelo: 'Modelo',
                marca: 'Marca',
                editar_coche: 'Editar coche',
                nuevo_coche: 'Nuevo coche',
                coche_guardado: 'Coche guardado',
                coche: 'Coche',
                documentacionAportada: 'Documentación aportada',
            }
        },
        marcas: {
            marcas: {
                marcas: 'Marcas'
            },
            marca_form: {
                nombre: 'Nombre',
                marca: 'Marca',
                editar_marca: 'Editar marca',
                nuevo_marca: 'Nueva marca',
                marca_guardada: 'Marca guardada',
                marca: 'Marca'
            }
        },
        modelos: {
            modelos: {
                modelos: 'Modelos'
            },
            modelo_form: {
                nombre: 'Nombre',
                modelo: 'Modelo',
                editar_modelo: 'Editar modelo',
                nuevo_modelo: 'Nuevo modelo',
                modelo_guardado: 'Modelo guardado',
                modelo: 'Modelo',
                marca: 'Marca'
            }
        },
        clientes: {
            clientes: {
                clientes: 'Clientes'
            },
            cliente_form: {
                nombre: 'Nombre',
                cif: 'CIF',
                tipoCliente: 'Tipo de cliente',
                ubicacion: 'Ubicación',
                eMail: 'Email',
                razonSocial: 'Razón social',
                clientePadre: 'Cliente padre',
                codigo_postal: 'Código postal',
                direccion: 'Dirección',
                poblacion: 'Población',
                provincia: 'Provincia',
                pais: 'País',
                cliente: 'Cliente',
                editar_cliente: 'Editar cliente',
                nuevo_cliente: 'Nuevo cliente',
                cliente_guardado: 'Cliente guardado',
                hora_apertura: 'Hora Apertura',
                hora_cierre: 'Hora Cierre',
                direccion_facturacion: 'Dirección Facturación',
                direccion_inspeccion: 'Dirección Certificación',
                configuraciones: 'Configuraciones',
                tiempo: 'Tiempo defecto',
                precio: 'Precio',
                formato_factura: 'Formato en Factura',
                tipo_configuracion: 'Tipo Configuración',
                tipo_inspeccion: 'Tipo Certificación',
                valor: 'Valor',
                matricula: 'Matrícula',
                bastidor: 'Bastidor',
                certificacion_1: 'Inspecciones de 1as',
                certificacion_2: 'Inspecciones de 2as',
                certificacion_M: 'Inspecciones de Marca',
                imagen: 'Seleccionar Imagen',
                firmas: 'Firmas',
                firmas_textoaviso: ' - ¡Antes de poder asociar firmas es necesario cargar alguna firma en el apartado de "Firmas disponibles" del cliente!',
                firmasDisponibles: 'Firmas disponibles',
                firmaMecanico: 'Firma mecánico', 
                firmaTecnico: 'Firma técnico', 
                firmaMaestroTaller: 'Firma maestro de taller', 
                firmaResponsableVehiculosOcasion: 'Responsable de vehículos de Ocasión', 
                firmaResponsableVO: 'Firma responsable de ventas', 
                firmaAsesorServicio: 'Firma asesor de servicio', 
                marcas: 'Marcas',
                add: 'Añadir',
                marca: 'Marca',
                alcance: 'Alcance',
                formato: 'Formato',
                nuevo_destino_aviso: 'Nuevo destino aviso',
                ayuda_destino_aviso: '** Envios de notificaciones de correo a destinatarios según el estado del vehículo en la inspección',
            },
            destino_aviso: {
                estado_inspeccion: 'Estado Proceso',
                estado_vehiculo: 'Estado Vehículo',
                departamento: 'Departamento',
                emails: 'Emails -- separados por ;  ',
            }
        },
        firmas: {
            firmas: {
                nombre: 'Marcas'
            },
            firma_form: {
                nombre: 'Nombre',
                firma: 'Firma',
                editar_firma: 'Editar Firma',
                nueva_firma: 'Nueva Firma'
            }
        },
        gruposfuncionales: {
            guardado_ok: 'Registro guardado correctamente',
            nuevo: 'Nuevo',
            editar: 'Editar',
            ver: 'Ver',
            gruposfuncionales: 'Grupos Funcionales',
            codigo: 'Código',
            nombre: 'Nombre',
            tipologia: 'Tipología',
            ignorarComentarios: 'Ignorar Comentarios'
        },
        formatos: {
            guardado_ok: 'Registro guardado correctamente',
            nuevo: 'Nuevo',
            editar: 'Editar',
            ver: 'Ver',
            formatos: 'Formatos',
            formato: 'Formato',
            codigo: 'Código',
            nombre: 'Nombre'
        },


        configuracion: {
            configuracion: 'Configuración',
            politica_privacidad: 'Política de privacidad',
            tema_oscuro: 'Tema oscuro',
            idioma: {
                idioma: 'Idioma',
                espanol: 'Español',
                ingles: 'Inglés'
            }
        },

        rlist: {
            pregunta_eliminar_registro: "¿Está seguro de que desea eliminar este registro?",
            registro_eliminado: "Registro eliminado con éxito",
            n_registros_seleccionados: "{count} registro seleccionado | {count} registros seleccionados",
            n_registros: "{count} registro | {count} registros"
        },
        rform: {
            error_subiendo_archivos: "Error subiendo archivos",
            error_validacion_formulario: "Hay errores en el formulario"
        },
        searchBarBuscar: "Buscar",
        searchBarSinResultados: "Sin resultados"
    }
}
export default messages;