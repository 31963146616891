<template>
  <f7-page name="home" :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :sliding="false">
      <f7-nav-left></f7-nav-left>
      <f7-nav-title sliding>Panel de control</f7-nav-title>
      <f7-nav-right></f7-nav-right>
    </f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <f7-list class="list-form">
        <f7-row>
          <f7-button
            fill
            v-roles="{route: $f7route.url, roles: [ 'RESPONSABLE']}"
            icon-f7="doc_text_fill"
            text="Crear Nueva Cita"
            class="display-flex margin col-25"
            href="/pedidos/new/"
          ></f7-button>
        </f7-row>
        <div class="dashboards">
          <div class="lista" v-if="list.filter(t => !t.tipo).length">
            <f7-card v-for="(item,index) in list.filter(t => !t.tipo)" :key="'lista1-'+index" :class="'dash-item ' + item.cardClass">
              <f7-card-content>
                <!-- <f7-icon f7="gear_alt_fill">{{item.imagen}}</f7-icon> -->
                <i class="f7-icons"
                  :class="'text-color-' + item.color"
                >{{item.imagen}}</i>
                {{item.nombre}}: <span @click="verDatos(item)" :class="'cursor-pointer badge'" :style="'color: ' + getLetterColor(item.color) + '; background-color:'+ item.color">{{item.valor}}</span>
              </f7-card-content>
            </f7-card>
          </div>
          <div class="lista" v-if="list.filter(t => t.tipo == 'VEHICULO').length">
            <f7-card v-for="(item,index) in list.filter(t => t.tipo == 'VEHICULO')" :key="'lista2-'+index"  >
              <f7-card-content>
                <!-- <f7-icon f7="gear_alt_fill">{{item.imagen}}</f7-icon> -->
                <i class="f7-icons"
                  :class="'text-color-' + item.color"
                >{{item.imagen}}</i>
                {{item.nombre}}: <span  :class="'cursor-pointer badge'" :style="'color: ' + getLetterColor(item.color) + '; background-color:'+ item.color">{{item.valor}}</span>
              </f7-card-content>
            </f7-card>
          </div>
          <div class="lista" v-if="list.filter(t => t.tipo == 'INSPECCION').length">
            <f7-card v-for="(item,index) in list.filter(t => t.tipo == 'INSPECCION')" :key="'lista3-'+index"  >
              <f7-card-content>
                <!-- <f7-icon f7="gear_alt_fill">{{item.imagen}}</f7-icon> -->
                <i class="f7-icons"
                  :class="'text-color-' + item.color"
                >{{item.imagen}}</i>
                {{item.nombre}}: <span :class="'cursor-pointer badge'" :style="'color: ' + getLetterColor(item.color) + '; background-color:'+ item.color">{{item.valor}}</span>
              </f7-card-content>
            </f7-card>
          </div>
        </div>
        <f7-row no-gap>
          <f7-col
            v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO', 'INSPECTOR', 'RESPONSABLE']}"
            width="100" large="50" 
          >
            <f7-card 
              v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO', 'INSPECTOR', 'RESPONSABLE']}"
            >
              <f7-card-header>
                DÍAS DE MEDIA EN CADA ESTADO DEL REACONDICIONAMIENTO
                <f7-row>
                  <f7-list-input
                    autocomplete="no"
                    label="Año"
                    :clear-button="false"
                    type="select"
                    :value="filter.annio"
                    @change="filter.annio = $event.target.value; searchDiasMediaEstado(filter);"
                  >
                    <option selected value=" "></option>
                    <option selected :value="new Date().getFullYear()">{{new Date().getFullYear()}}</option>
                    <option :value="new Date().getFullYear()-1">{{new Date().getFullYear()-1}}</option>
                    <option :value="new Date().getFullYear()-2">{{new Date().getFullYear()-2}}</option>
                  </f7-list-input>
                  <f7-list-input
                    label="Mes"
                    autocomplete="no"
                    :clear-button="false"
                    type="select"
                    :value="filter.mes"
                    @change="filter.mes = $event.target.value; searchDiasMediaEstado(filter);"
                  >
                    <option selected ></option>
                    <option value="1">Enero</option>
                    <option value="2">Febrero</option>
                    <option value="3">Marzo</option>
                    <option value="4">Abril</option>
                    <option value="5">Mayo</option>
                    <option value="6">Junio</option>
                    <option value="7">Julio</option>
                    <option value="8">Agosto</option>
                    <option value="9">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                  </f7-list-input>
                </f7-row>
              </f7-card-header>
              <f7-card-content >
                <apexchart v-if="series && series.length" type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
              </f7-card-content>
            </f7-card>
          </f7-col>
        </f7-row>
      </f7-list>
    </f7-page-content>
  </f7-page>
</template>
<style scoped>
  .badge {
    top: -2px;
  }
  .dashboards .lista {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .dashboards .dash-item {
    flex: 1 1 25%;
  }
  .dashboards .card-orange {
    background-color: #ffc107;
  }
  .dashboards .card-listo-venta {
    background-color: #f2bce8;
  }
  .dashboards .card-col-50 {
    flex-basis: 40%;
  }
  .cursor-pointer {
    cursor: pointer;
  }
</style>
<script>
import Dashboard from "./../js/db/Dashboard.js";
import Anyo from "./../js/db/Anyo.js";
import Mes from "./../js/db/Mes.js";
import Cliente from "./../js/db/Cliente.js";
import barChart from "./../components/charts/barChart.vue";
import pieChart from "./../components/charts/pieChart.vue";
import rForm from "./../components/rForm.vue";
import rInput from "./../components/rInput.vue";
import rAutocomplete from "./../components/rAutocomplete.vue";

export default {
  components: {
    barChart,
    pieChart,
    rForm,
    rInput,
    rAutocomplete
  },
  data() {
    return {
      Dashboard, 
      Anyo,
      Mes,
      Cliente,
      list: [],
      inspeccionesRealizadasConc : [],
      inspeccionesRealizadasMod : [],
      inspeccionesKOConc : [],
      inspeccionesKOMod : [],
      comprobacionesKO : [],
      promesadefiltro : null,
      nombreCliente: '',
      series: [],
      filter: {
        annio: new Date().getFullYear(),
        mes: null
      },
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          },
        },
        dataLabels: {
          style: {
            colors: ['black']
          },
          dropShadow: {
            enabled: false
          }
        },
        xaxis: {
          categories: [],
        },
        legend: {
          position: 'right',
          offsetY: 40
        },
        fill: {
          opacity: 1
        }
      },
    };
  },
  methods: {
    getLetterColor: function(hexcolor) {
      // If a leading # is provided, remove it
      if (hexcolor.slice(0, 1) === '#') {
        hexcolor = hexcolor.slice(1);
      }

      // Convert to RGB value
      var r = parseInt(hexcolor.substr(0,2),16);
      var g = parseInt(hexcolor.substr(2,2),16);
      var b = parseInt(hexcolor.substr(4,2),16);

      // Get YIQ ratio
      var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

      // Check contrast
      return (yiq >= 128) ? 'black' : 'white';
    },
    searchInpeccionesRealizadas: function(filter) {
      var self = this;
      Dashboard.inspeccionesRealizadas(filter).then(function(res) {
        if (filter.groupBy == "CONCESIONARIO") {
          self.inspeccionesRealizadasConc = res.dto.valores;
        } else if (filter.groupBy == "MODELO") {
          self.inspeccionesRealizadasMod = res.dto.valores;
        }
      });
    },
    searchInpeccionesKO: function(filter) {
      var self = this;
      Dashboard.inspeccionesKO(filter).then(function(res) {
        if (filter.groupBy == "CONCESIONARIO") {
          self.inspeccionesKOConc = res.dto.valores;
        } else if (filter.groupBy == "MODELO") {
          self.inspeccionesKOMod = res.dto.valores;
        }
      });
    },
    verDatos: function(item) {
      var self = this;
      self.$f7router.navigate(item.url + "?" + item.urlParams);
    },
    searchComprobacionesKO: function(filter) {
      var self = this;
      let promesaactual = self.promesadefiltro = Dashboard.comprobacionesKO(filter);
      self.comprobacionesKO = [];
      promesaactual.then(function(res) {
        if(self.promesadefiltro !== promesaactual) {
          return;
        }
        self.comprobacionesKO = res.dto.valores;
        self.promesadefiltro = null;
        let params = {
          itemId: filter.clienteId
        };
        if(!filter.clienteId) {
          self.$set(self, 'nombreCliente', '');
        } else {
          Cliente.get(params).then(function(res) {
            self.$set(self, 'nombreCliente', res?res.nombre:'');
          }).catch(function({ xhr, status, message }) {
            self.$set(self, 'nombreCliente', '');
          });
        }
      });
    },
    searchDiasMediaEstado: function(filter) {
      var self = this;
      Dashboard.diasMediaEstado(filter).then(function (res) {
        self.series = res.dto.series;
        self.chartOptions.xaxis.categories = res.dto.categories;
      }); 
      // let promesaactual = self.promesadefiltro = Dashboard.comprobacionesKO(filter);
      // self.comprobacionesKO = [];
      // promesaactual.then(function(res) {
      //   if(self.promesadefiltro !== promesaactual) {
      //     return;
      //   }
      //   self.comprobacionesKO = res.dto.valores;
      //   self.promesadefiltro = null;
      //   let params = {
      //     itemId: filter.clienteId
      //   };
      //   if(!filter.clienteId) {
      //     self.$set(self, 'nombreCliente', '');
      //   } else {
      //     Cliente.get(params).then(function(res) {
      //       self.$set(self, 'nombreCliente', res?res.nombre:'');
      //     }).catch(function({ xhr, status, message }) {
      //       self.$set(self, 'nombreCliente', '');
      //     });
      //   }
      // });
    }
  },
  created: function() {
    var self = this;
    var app = self.$f7;
    Dashboard.getList().then(function(res) {
      self.$set(self, 'list', res.list);
    }); 
    Dashboard.diasMediaEstado().then(function (res) {
      self.series = res.dto.series;
      self.chartOptions.xaxis.categories = res.dto.categories;
    }); 
  }
};
</script>