<template>
  <div>
    <div v-if="title">
      {{title}}
    </div>
    <div v-if="preview" class="rfiles-container margin-horizontal">
      <div class="rfiles-item" v-for="(item,iaf) in getValue()" :key="'file-'+prefixKey+'-'+iaf">
        {{ item.nombre }}
        <span class="rfiles-close" v-if="overrideReadonly ? !readonly : !readonly && !form.readonly" @click="deleteItem(iaf)">
          <f7-icon v-tooltip="'Eliminar'" f7="multiply_circle_fill" />
        </span>
        <a>
          <img
            v-tooltip="item.nombre"
            :src="getSrc(item)"
            @click="download(item)"
          />
        </a>
      </div>
    </div>
    <slot name="add" v-if="(overrideReadonly ? !readonly : !readonly && !form.readonly) && (!maxFiles||!getValue()||getValue().length<maxFiles)">
      <input :multiple="!maxFiles || maxFiles > 1" type="file" ref="input" style="display:none;" @change="loadFile" :accept="fileExtensions"/>
      <f7-block> 
        <f7-button @click="$refs.input.click()">{{label}}</f7-button>
      </f7-block>
    </slot>
  </div>
</template>
<script>
import Documento from "./../js/db/Documento.js";
export default {
  components: {},
  data() {
    return {
      self: this
    };
  },
  props: {
    name: {
      required: true,
      type: String
    },
    isObject: {
      type: Boolean,
      default: false
    },
    form: {
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    overrideReadonly: {
      type: Boolean,
      default: false
    },
    prefixKey: {
      default: ''
    },
    label: {
      default: 'Añadir documento'
    }, 
    fileExtensions: {
      default: '.*'
    }, 
    title: {
      required: false,
      type: String
    }, 
    maxFiles: {
      type: Number,
      default: undefined
    },
    preview: {
      type: Boolean,
      default: true
    },
    documentoAdapter: {
      type: Object,
      default: ()=>{return Documento;}
    }
  },
  methods: {
    getValue: function() {
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (typeof context == "undefined") {
          let n = namespaces[i];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            context = [];
          } else {
            context = {};
          }
        }
        context = context[namespaces[i]];
      }
      if (context && !Array.isArray(context)) {
        context = [context];
      }
      
      if(self.isObject) {
        return context?[context]:[];
      } else {
        return context;
      }
    },
    append: function(val) {
      const self = this;
      const namespaces = self.name.split(".");
      let context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (i == namespaces.length - 1) {
          if (
            typeof context[namespaces[i]] == "undefined" ||
            !context[namespaces[i]]
          )
          self.$set(context, namespaces[i], []);
          if(self.isObject) {
            context[namespaces[i]] = val;
          } else {
            context[namespaces[i]].push(val);
          }
        } else if (typeof context[namespaces[i]] == "undefined") {
          let n = namespaces[i + 1];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            self.$set(context, namespaces[i], []);
          } else {
            self.$set(context, namespaces[i], {});
          }
        }
        context = context[namespaces[i]];
      }
    },
    deleteItem: function(index) {
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (i == namespaces.length - 1) {
          if (
            typeof context[namespaces[i]] == "undefined" ||
            !context[namespaces[i]]
          ) {
            self.$set(context, namespaces[i], []);
          }
          if (context[namespaces[i]] && !Array.isArray(context[namespaces[i]])) {
            self.$delete(context, namespaces[i]);
          } else {
            self.$delete(context[namespaces[i]], index);
          }
        } else if (typeof context[namespaces[i]] == "undefined") {
          let n = namespaces[i + 1];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            self.$set(context, namespaces[i], []);
          } else {
            self.$set(context, namespaces[i], {});
          }
        }
        context = context[namespaces[i]];
      }
    },
    loadFile: function(event) {
      var self = this;
      var app = self.$f7;
      var value = self.getValue();
      var inputFilesLength = event.target.files.length;
      if (self.maxFiles && (inputFilesLength + (value && value != null ? self.getValue().length : 0)) > self.maxFiles) {
        app.dialog.alert("El número máximo de ficheros a añadir es " + self.maxFiles);
        self.$refs.input.value = "";
        return false;
      }
      var filesLoaded = [];
      var inputFilesLength = event.target.files.length;
      Array.from(event.target.files).forEach(file => {
        var reader = new FileReader();
        reader.onload = function(e) {
          var fileLoaded = {
            nombre: file.name
              .split(".")
              .slice(0, -1)
              .join("."),
            extension: /(?:\.([^.]+))?$/.exec(file.name)[1], //file.type.split("/")[1],
            _base64: e.target.result
          };
          self.append(fileLoaded);
          filesLoaded.push(fileLoaded);
          app.preloader.hide();
          self.$emit("loadFile", fileLoaded);
          if (filesLoaded.length == inputFilesLength) {
            self.$emit("loadFiles", filesLoaded);
          }
        };
        reader.readAsDataURL(file);
        app.preloader.show();
      });
      self.$refs.input.value = "";
    },
    getSrc(item) {
      const self = this;
      const app = self.$f7;
      if(Array.isArray(item) && item.length == 1) {
        item = item[0];
      }
      switch (item.extension.toLowerCase()) {
        case "pdf":
          return "static/rfiles/pdf.png";
          break;
        case "doc":
        case "docx":
          return "static/rfiles/doc.png";
          break;
        case "xls":
        case "xlsx":
          return "static/rfiles/xls.png";
          break;
        case "png":
        case "jpg":
        case "jpeg":
        case "gif":
          if (!item._base64 && self.target != 'cordova' && !item.cargando) {
            item.cargando = true;
            self.documentoAdapter.getDocumento(item).then(function(data){
              var blob = new Blob([data]);
              var reader = new FileReader();
              reader.readAsDataURL(blob); 
              reader.onloadend = function() {
                  var base64data = reader.result;                
                  self.$set(item, '_base64', base64data);
              }
            });
          }
          if (self.target == 'cordova') {
            return item._base64
              ? item._base64
              : app.data.appDataPath +
                  "/documentos/" +
                  item.nombre +
                  "." +
                  item.extension;
          } else {
            return item._base64
              ? item._base64
              : "static/rfiles/attachment-icon.png" ;
          }
          break;
        case "mp4":
        case "mkv":
        case "vob":
        case "avi":
        case "mov":
          return "static/rfiles/video.png";
          break;
        default:
          return "static/rfiles/attachment-icon.png";
      }
    },
    download(item) {
      var self = this;
      // if (self.target == 'web') {
        self.documentoAdapter.download(item);
      // }
    }
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
  }
};
</script>
<style>
.rfiles-container {
  display: flex;
  overflow: auto;
}
.rfiles-item {
  height: 100px;
  cursor: pointer;
  position: relative;
  margin: 3px;
}
.rfiles-item a {
  display: block;
  height: 100%;
}
.rfiles-item img {
  height: 100%;
}
.rfiles-close {
  position: absolute;
  right: -5px;
  top: 0;
  background: white;
  border-radius: 50%;
}
</style>
