<template>
  <f7-popup ref="popup" v-if="popupAbierto" :opened="true" class="popup-modificar-estado" @popup:closed="popupAbierto = false; " swipe-to-close>
    <f7-page>
      <f7-navbar :title="popupTitle">
        <f7-nav-right>
          <f7-link popup-close>{{$t('common.cerrar')}}</f7-link>
        </f7-nav-right>
      </f7-navbar>
    <f7-card
      class="elevation-3 padding-vertical padding-right"
    >
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="inspeccion?inspeccion.id:null"
      :readonly="false"
      :initialData="inspeccion ? inspeccion : null"
      :defaultData="inspeccion ? inspeccion : null"
      @save="onSave"
    >
      <f7-card-content v-if="todosEstados">
        <f7-list class="list-form">
          <ul>
            <f7-row>
              <f7-col width="100" medium="100">
                <r-input
                  floating-label
                  :label="$t('inspecciones.inspeccion_form.estado')"
                  :form="rform"
                  type="select"
                  name="nuevoEstado"
                  @change="gestionarEstado"
                >
                  <option value></option>
                  <option value="PLANIFICADO">PENDIENTES DE ENTRADA</option>
                  <option value="VEHICULO_PRESENTE">PRESENTE</option>
                  <option value="PENDIENTE_PARTES">PENDIENTE DE PARTES</option>
                  <option value="PENDIENTE_VALORACION">VALORACIÓN DE DAÑOS</option>
                  <option value="PENDIENTE_ACEPTACION">PENDIENTE ACEPTAR PRESUPUESTO</option>
                  <option value="PERITADO">PERITADO</option>
                  <option value="EN_TALLER">EN TALLER</option>
                  <option value="CONTROL_FINAL">CONTROL CALIDAD</option>
                  <option value="FINALIZADO">LISTO PARA REESTRENO</option>
                  <option value="VENDIDO">ENTREGADO A CLIENTE</option>
                  <option value="CANCELADA">CANCELADO</option>
                </r-input>
                <r-input
                  v-if="nuevoEstado == 'EN_TALLER'"
                  floating-label
                  :label="$t('inspecciones.inspeccion_form.departamento')"
                  :form="rform"
                  type="select"
                  name="nuevoDepartamento"
                  @change="gestionarDepartamento"
                >
                  <option value></option>
                  <option v-for="(dep, i) in departamentos" :key="'departamento-'+i" :value="dep.valor">{{dep.nombre}}</option>
                </r-input>
                <r-input
                    floating-label
                    label="Comentario"
                    :form="rform"
                    type="textarea"
                    name="comentario"
                  ></r-input>
              </f7-col>
            </f7-row>
          </ul>
        </f7-list>
      </f7-card-content>
      <!-- <f7-card-content v-if="inspeccion && inspeccion.situacion && inspeccion.situacion.camposPersonalizadosArr && inspeccion.situacion.camposPersonalizadosArr.length">
        <f7-list class="list-form">
          <ul>
            <f7-row>
              <f7-col v-for="(campo,index) in inspeccion.situacion.camposPersonalizadosArr" :key="'situacion-campoPersonalizado-' + index" 
                  width="100" medium="50">
                <r-input
                  floating-label
                  :label="campo.titulo"
                  :form="rform"
                  :name="'situacion.camposPersonalizados.'+campo.nombre"
                ></r-input>
              </f7-col>
            </f7-row>
          </ul>
        </f7-list>
      </f7-card-content> -->
    </r-form>
    <p v-if="!todosEstados" class="margin-left margin-right text-align-center">{{$t('inspecciones.inspeccion_form.confirmacionEnvioATaller')}}</p>
      <f7-button
        v-if="nuevoEstado"
        v-roles="{route: $f7route.url, roles: ['ADMIN']}"
        fill
        icon-f7="checkmark_circle_fill"
        :text="$t('common.forzar')"
        class="display-flex margin-left color-green"
        @click="forzar=true;$refs.rform.saveItem()"
      ></f7-button>
      <f7-button
        v-if="nuevoEstado"
        v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO','RESPONSABLE']}"
        fill
        icon-f7="checkmark_circle_fill"
        :text="$t('common.confirmar')"
        class="display-flex margin-left"
        @click="forzar=false;$refs.rform.saveItem()"
      ></f7-button>
    </f7-card>
      <!-- </div> -->
    </f7-page>
  </f7-popup>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rForm from "../../../components/rForm.vue";
import rInput from "../../../components/rInput.vue";
import Inspeccion from "../../../js/db/Inspeccion.js";
import Departamento from "../../../js/db/Departamento.js";
import Utils from "../../../js/Utils.js";

export default {
  components: {
    rForm,
    rInput
  },
  data() {
    return {
      Inspeccion,
      Departamento,
      Utils,
      departamentos: [],
      popupAbierto: false,
      todosEstados: true,
      nuevoEstado: null,
      nuevoDepartamento: null,
      forzar: false
    };
  },
  props:{
    inspeccion:{
      type: Object
    }
  },
  computed: {
    popupTitle: function(){
      const self = this;
      return !self.todosEstados ? self.$t("inspecciones.inspeccion_form.mandarATaller") : self.$t("inspecciones.inspeccion_form.cambiarEstado");
    }
  },
  methods: {
    gestionarEstado: function(nuevoEstado){
      const self = this;
      if(nuevoEstado && !nuevoEstado.target){
        self.nuevoEstado = nuevoEstado;
      } else if(!nuevoEstado) {
        self.nuevoEstado = nuevoEstado;
      }
      if(nuevoEstado != "EN_TALLER"){
        self.nuevoDepartamento = null;
      }
    },
    gestionarDepartamento: function(nuevoDepartamento){
      const self = this;
      if(nuevoDepartamento && !nuevoDepartamento.target){
        self.nuevoDepartamento = nuevoDepartamento;
      }
    },
    preparado: function(item) {
      return item.estado && item.estado.estado && item.estado.estado == "PERITADO";
    },
    pasarATaller: function() { // TODO Quitar
      const self = this;
      self.todosEstados = false;
      self.popupAbierto = true;
    },
    cambiarEstado: function() {
      const self = this;
      self.todosEstados = true;
      self.popupAbierto = true;
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var p = null;
      if(self.todosEstados){
        var dataRaw = self.$refs.rform.formData;
        var data = {
          estado: dataRaw.nuevoEstado,
          departamento: dataRaw.nuevoDepartamento,
          comentario: dataRaw.comentario
        };
        if(self.forzar) {
          p = Inspeccion.forzarcambioEstado(self.inspeccion.id, data);
        } else {
          p = Inspeccion.cambioEstado(self.inspeccion.id, data);
        }
      } else {
        p = Inspeccion.enviarATaller(self.inspeccion.id);
      }
      if(p != null){
        app.preloader.show();
        p.then(function(res) {
            if (res) {
              app.toast
                .create({
                  text: self.$t("inspecciones.inspeccion_form.inspeccion_guardada"),
                  position: "center",
                  closeTimeout: 2000,
                  icon: '<i class="f7-icons">checkmark_alt</i>'
                })
                .open();
              self.$emit("finished");
            }
          }).catch(function(error){
            app.dialog.alert(error.message || "Error");
          }).finally( _ => {
            app.preloader.hide();
            // self.$refs.popup.close();
            // self.$refs.rform.clear();
          });
      }
      self.$refs.popup.close();
      self.$refs.rform.clear();
    }
  },
  mounted: function(){
    const self = this;
    Departamento.getCombo().then(res => {console.log("departamentos", res); self.departamentos = res.list});
  }
};
</script>