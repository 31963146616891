<template>
  <f7-popup ref="popup" v-if="popupAbierto" :opened="true" class="popup-control-calidad" @popup:closed="popupAbierto = false; " swipe-to-close>
    <f7-page>
      <f7-navbar title="Control de calidad">
        <f7-nav-right class="btn-confirmar">
          <f7-link @click="guardarFormulario()">{{$t('common.confirmar')}}</f7-link>
        </f7-nav-right>
        <f7-nav-right>
          <f7-link popup-close>{{$t('common.cerrar')}}</f7-link>
        </f7-nav-right>
      </f7-navbar>
    <f7-card
      class="elevation-3 padding-vertical padding-right"
    >
      <f7-button
        fill
        icon-f7="checkmark_circle_fill"
        :text="$t('common.confirmar')"
        class="display-flex margin-left"
        @click="guardarFormulario()"
      ></f7-button>
    </f7-card>
    <f7-card
      class="elevation-3 padding-vertical padding-right"
    >
    <div ref="head" v-content="head"></div>
    <div ref="content" v-content="body" @input="updatedContent"></div>
    </f7-card>
    </f7-page>
  </f7-popup>
</template>
<style scoped>
  .btn-confirmar{
    position: absolute;    
  }
  .popup-control-calidad{
    width: 900px;
  }
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
  .page-container {
    margin-top: 60px;
  }
</style>
<script>
import rForm from "../../../components/rForm.vue";
import rInput from "../../../components/rInput.vue";
import Inspeccion from "../../../js/db/Inspeccion.js";
import Utils from "../../../js/Utils.js";

export default {
  components: {
    rForm,
    rInput
  },
  data() {
    return {
      Inspeccion,
      Utils,
      popupAbierto: false,
      wrapper: "",
      body: "",
    };
  },
  props:{
    inspeccion:{
      type: Object
    }
  },
  computed: {
    html: function(){
      const self = this;
      return self.wrapper.replace("{body}", self.body);
    }
  },
  directives: {
    content (el, { value }) {
      if (el.innerHTML !== value) {
        el.innerHTML = value
      }
    }
  },
  methods: {
    preparado: function(item) {
      const estadosPosibles = ["PERITADO", "EN_TALLER", "CONTROL_FINAL"];
      return item && item.estado && item.estado.estado && estadosPosibles.includes(item.estado.estado);
    },
    openPopup: function(item) {
      const self = this,
        app = self.$f7;
      if(!item.htmlControlCalidad){
        app.preloader.show();
        Inspeccion.getPlantillaControl(item)
          .then(response => {
            self.prepareContent(response, true);
            self.popupAbierto = true
          })
          .finally(_=>app.preloader.hide());
      } else {
        self.prepareContent(item.htmlControlCalidad, true);
        self.popupAbierto = true
      }
    },
    prepareContent: function(content, initial = false){
      const self = this;
      let splitHead = content.replace("<head>", "</head>").split("</head>");
      let splitContent = content.replace("<body>", "</body>").split("</body>");
      if(initial && splitHead.length > 1){
        let head = splitHead[1];
        self.head = head;
      }
      if(splitContent.length > 1){
        let body = splitContent[1],
          wrapper = `${splitContent[0]}<body>{body}</body>${splitContent[2]}`;
        if(initial){
          self.wrapper = wrapper;
        }
        self.body = body;
      } else {
        if(initial){
          self.wrapper = "<body>{body}</body>";
        }
        self.body = content;
      }
      return;
    },
    updatedContent: function(refreshDOM = false) {
      const self = this;
      if(refreshDOM){
        const inputs = Array.from(self.$refs.content.getElementsByTagName("input")),
          textInputs = inputs.filter(input => input.getAttribute("type") == "text"),
          checkboxInputs = inputs.filter(input => input.getAttribute("type") == "checkbox");
        textInputs.forEach(input => input.setAttribute("value", input.value));
        checkboxInputs.forEach(input => {
          if(input.checked){
            input.setAttribute("checked","checked");
          } else {
            input.removeAttribute("checked");
          }
        });

      }
      self.prepareContent(self.$refs.content.innerHTML);
    },
    guardarFormulario: function() {
      const self = this;
      self.updatedContent(true);
      const app = self.$f7,
        plantilla = self.tratarPlantilla(self.html),
        id = self.inspeccion.id;
      app.preloader.show();
      Inspeccion.guardarControlCalidad(id, plantilla)
        .then(response => Inspeccion.getControlCalidad(response))
        .finally(_=>{
          app.preloader.hide();
          self.$refs.popup.close();
          self.$emit("finished");
        });
    },
    tratarPlantilla: function(plantilla){
      return plantilla.replaceAll(/<input([^>]*)>/g, "<input$1/>").replaceAll(/<img([^>]*)>/g, "<img$1/>");
    }
  },
  created: function(){
  }
};
</script>